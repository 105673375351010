<template>
  <header class="h-[100px] fixed z-[1000] bg-[#03051E] w-full flex items-center justify-between gap-[5%]">
    <nav class=" text-white flex gap-[20px] items-center ml-[4%] ">
      <router-link class=" mr-[50px] " to="/"> <img class="h-[80px]" src="@/assets/img/logoWhite.svg" alt=""></router-link>
      <div class="flex gap-[20px] max-md:hidden">
        <router-link to="/produit">produit</router-link>
        <router-link to="/engagement">engagement</router-link>
        <router-link to="/retrouver">retrouver</router-link>
      </div>
    </nav>
    <div class="flex  gap-[20px] mr-[4%] max-md:hidden">
      <i class="fa-regular fa-user text-white text-[25px]"></i>
      <div class="relative">
        <i class="kart fa-solid fa-cart-shopping text-white text-[25px]"></i>
        <span class="badge" v-if="cartCount > 0">{{ cartCount }}</span>
      </div>
    </div>
    <i class="text-white fa-solid fa-bars text-2xl hidden max-md:flex"></i>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HeaDer',
  computed: {
    ...mapGetters(['cartCount'])
  }
}
</script>

<style scoped>
.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 12px;
}
</style>