import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/produit',
    name: 'produit',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProDuit.vue')
  },
  {
    path: '/engagement',
    name: 'engagement',
    component: () => import(/* webpackChunkName: "about" */ '../views/EnGagement.vue')
  },
  {
    path: '/produit',
    name: 'produit',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProDuit.vue')
  },
  {
    path: '/retrouver',
    name: 'retrouver',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReTrouver.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
