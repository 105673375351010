<script>
import HeaDer from '@/components/HeaDer.vue'
import FooTer from '@/components/FooTer.vue'
import SliDer from '@/components/SliDer.vue'

export default {
  name: 'AppView',
  components: {
    HeaDer,
    FooTer,
    SliDer
  }
}
</script>

<template>
  <div>
    <HeaDer />
    <br>
    <SliDer />

    <router-view v-slot="{ Component }">

<transition name="page-op" mode="out-in">
    <component :is="Component" />
</transition>   

</router-view>

    <FooTer />
  </div>
</template>


<style>


  @keyframes slide-in {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-out {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(-100%);
      opacity: 1;
    }
  }
  
  .page-op-enter-active {
    animation: slide-in 0.2s forwards;
  }
  
  .page-op-leave-active {
    animation: slide-out 0.2s forwards;
  }



</style>