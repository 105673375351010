<template>
  <footer>
  <div class="bg-[#030411] flex justify-center gap-[12vw] py-8">
    <div class="flex items-center gap-4">
      <i class="fa-solid fa-location-dot text-white text-[26px]"></i>
      <div class="flex flex-col">
        <p class="text-[16px] text-white">ADRESSE</p>
        <p class="text-[12px] text-[#BDBABB]">33 route de la fleure, 14000 Caen</p>
      </div>
    </div>
    <div class="flex items-center gap-4">
      <i class="fa-solid fa-phone text-white text-[26px]"></i>
      <div class="flex flex-col">
        <p class="text-[16px] text-white">TÉLÉPHONE</p>
        <p class="text-[12px] text-[#BDBABB]">06 06 06 06 06</p>
      </div>
    </div>
    <div class="flex items-center gap-4">
      <i class="fa-solid fa-envelope text-white text-[26px]"></i>
      <div class="flex flex-col">
        <p class="text-[16px] text-white">E-MAIL</p>
        <p class="text-[12px] text-[#BDBABB]">contact@tazzy.com</p>
      </div>
    </div>
  </div>

<div class="bg-[#04051C]">
  <div class="margin flex justify-around items-center py-12">
    <div>
      <router-link to="/">
        <img src="@/assets/img/logoWhite.svg" alt="Tazzy Logo">
      </router-link>
    </div>

    <div class="flex justify-end gap-[12vw]">
      <div class="flex flex-col gap-6">
        <a class="text-white text-[23px] MEFK" href="#">Tazzy</a>
        <div class="flex flex-col gap-1">
          <a class="Sen text-[#BDBABB] text-[19px]" href="#">Home</a>
          <a class="Sen text-[#BDBABB] text-[19px]" href="#">Produit</a>
          <a class="Sen text-[#BDBABB] text-[19px]" href="#">Nos engagements</a>
          <a class="Sen text-[#BDBABB] text-[19px]" href="#">Nous retrouver</a>
          <a class="Sen text-[#BDBABB] text-[19px]" href="#">E-boutique</a>
          <a class="Sen text-[#BDBABB] text-[19px]" href="#">Se connecter</a>
          <a class="Sen text-[#BDBABB] text-[19px]" href="#">Se déconnecter</a>
        </div>
      </div>

      <div class="flex flex-col gap-4 w-[30%] items-center">
        <h1 class="text-white MEFK text-[20px]">Newsletter</h1>
        <p class="text-[#BDBABB] Sen text-center">Convaincu ? Tu souhaites être informé des dernières actualités de Tazzy ? Inscris-toi gratuitement à cette newsletter pour rester fidèle à Tazzy.</p>
        <input type="email" placeholder="Email" class="w-full bg-[#BDBABB] rounded-tr-lg rounded-bl-lg p-2">
        <div class="flex gap-2 items-start">
          <input type="checkbox" class="mt-1">
          <p class="text-[#BDBABB] text-[12px]">
            En soumettant ce formulaire, j’accepte que mes informations soient utilisées exclusivement dans le cadre de ma demande et de la relation commerciale éthique et personnalisée qui pourrait en découler.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-[#030411]">
  <div class="MEFK margin py-4 text-[#BDBABB] text-[16px] flex gap-4">
    <p>Copyright © 2024 - Tous droits réservés.</p>
    <a href="#" class="">Mentions légales</a>
  </div>
</div>
</footer>

</template>


<script>
import Lenis from '@studio-freight/lenis'

export default {
  name: 'FooTer',
  mounted() {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      direction: 'vertical',
      gestureDirection: 'vertical',
      smooth: true,
      mouseMultiplier: 1.25,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    })
    
    lenis.on('scroll', () => {
      // Ajoutez ici votre logique pour les événements de défilement si nécessaire
    })


    const raf = (time) => {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)
  }
}
</script>
