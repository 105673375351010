<template>
  <section class="home mt-[5%]">
    <!-- Section Qui Sommes Nous -->
    <div class="flex">
      <div class="margin flex justify-between w-full">
        <div class="flex flex-col">
          <p class="mb-[2%] font-bold text-[2.5vw]">Qui Sommes Nous</p>
          <p class="mb-[2%] text-[1.5vw] font-bold">En savoir plus sur Tazzy</p>
          <p class="w-[40vw] mb-[4%] text-[1vw] max-[768px]:text-[2vw] max-[768px]:w-full">Tazzy est une marque innovante qui se concentre sur la production de bonbons énergétiques, conçus pour offrir un regain d'énergie rapide et efficace. Contrairement aux boissons énergétiques traditionnelles, les bonbons Tazzy sont une alternative pratique et savoureuse pour ceux qui recherchent un coup de boost, que ce soit pour faire face à une journée chargée ou pour améliorer leurs performances physiques et mentales. La marque se distingue en proposant un produit qui allie plaisir gustatif et fonctionnalité.
            <br><br>
            Ce qui rend Tazzy encore plus unique, c’est son engagement envers l’environnement. La marque adopte une approche écoresponsable en sélectionnant des ingrédients naturels et en veillant à limiter son empreinte écologique. Elle privilégie des pratiques durables dans l’ensemble de sa chaîne de production, en utilisant notamment des emballages recyclables et des processus de fabrication respectueux de l’environnement.
          </p>
          <button class="btn">En savoir plus</button>
        </div>
        <img src="@/assets/img/qui.png" alt="" class="w-[80vw] md:w-[30vw] h-auto max-[768px]:hidden object-cover">
      </div>
    </div>

    <!-- Section Produits -->
    <div class="produit">
      <p class="tit">Les produits By Tazzy</p>
      <p class="stit">Vous pourrez retrouver ci-dessous <br> notre large gamme de produits by Tazzy</p>
      
      <!-- <div class="filter">
        <div></div>
        <div></div>
      </div> -->
    </div>

    <!-- Section Bonbons -->
    <section class="margin2 max-[882px]:flex-col">
      <div class="bonbon">
        <div class="bn">
          <div class="bnimg">
            <img src="@/assets/img/bonbon1.png" alt="">
          </div>
          <div class="flex flex-col gap-4">
            <div class="flex justify-between items-center mt-4">
              <p class="marque">L’EK’Tazzy</p>
              <div class="flex flex-col items-end">
                <div class="flex gap-2">
                  <span class="flex bg-[#BDBABB] w-[1vw] h-[1vw] rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-[1vw] h-[1vw] rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-[1vw] h-[1vw] rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-[1vw] h-[1vw] rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-[1vw] h-[1vw] rounded-full"></span>
                </div>
                <p class="text-[7px]">12 avis</p>
              </div>
            </div>
            <p class="pres">Une petite envie de te redonner un giga coup de boost ? L’EK’Tazzy est fait pour toi ! Grâce à nos vitamines B3 et B12, ce bonbon est parfait pour te faire tenir toute une nuit blanche sans ressentir l’envie de te glisser dans ton lit.</p>
            <input type="number" min="0" value="0" v-model.number="localKaradd1" @input="updateKaradd1(localKaradd1)" class="karadd-1 border border-gray-300 rounded-md p-2">
          </div>
        </div>
      </div>

      <!-- Repeat for other bonbons -->
      <div class="bonbon">
        <div class="bn">
          <div class="bnimg">
            <img src="@/assets/img/bonbon2.png" alt="">
          </div>
          <div class="flex flex-col gap-4">
            <div class="flex justify-between items-center mt-4">
              <p class="marque">L’EK’Tazzy</p>
              <div class="flex flex-col items-end">
                <div class="flex gap-2">
                  <span class="flex bg-[#BDBABB] w-4 h-4 rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-4 h-4 rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-4 h-4 rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-4 h-4 rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-4 h-4 rounded-full"></span>
                </div>
                <p class="text-[7px]">12 avis</p>
              </div>
            </div>
            <p class="pres">Une petite envie de te redonner un giga coup de boost ? L’EK’Tazzy est fait pour toi ! Grâce à nos vitamines B3 et B12, ce bonbon est parfait pour te faire tenir toute une nuit blanche sans ressentir l’envie de te glisser dans ton lit.</p>
            <input type="number" min="0" value="0" v-model.number="localKaradd2" @input="updateKaradd2(localKaradd2)" class="karadd-2 border border-gray-300 rounded-md p-2">
          </div>
        </div>
      </div>
      
      <div class="bonbon">
        <div class="bn">
          <div class="bnimg">
            <img src="@/assets/img/bonbon3.png" alt="">
          </div>
          <div class="flex flex-col gap-4">
            <div class="flex justify-between items-center mt-4">
              <p class="marque">L’EK’Tazzy</p>
              <div class="flex flex-col items-end">
                <div class="flex gap-2">
                  <span class="flex bg-[#BDBABB] w-4 h-4 rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-4 h-4 rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-4 h-4 rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-4 h-4 rounded-full"></span>
                  <span class="flex bg-[#BDBABB] w-4 h-4 rounded-full"></span>
                </div>
                <p class="text-[7px]">12 avis</p>
              </div>
            </div>
            <p class="pres">Une petite envie de te redonner un giga coup de boost ? L’EK’Tazzy est fait pour toi ! Grâce à nos vitamines B3 et B12, ce bonbon est parfait pour te faire tenir toute une nuit blanche sans ressentir l’envie de te glisser dans ton lit.</p>
            <input type="number" min="0" value="0" v-model.number="localKaradd3" @input="updateKaradd3(localKaradd3)" class="karadd-3 border border-gray-300 rounded-md p-2">
          </div>
        </div>
      </div>
    </section>

    <div class="flex justify-center">
      <button class="btn2">En savoir plus</button>
    </div>

    <!-- Section Partenaires -->
    <section class="overflow-hidden">
      <div class="triangleHaut"></div>
      <div class="partenaire">
        <div class="flex justify-between items-center max-[1000px]:flex-col">
          <div class="min-[1000px]:ml-[12%] flex flex-col w-[50vw]">
            <p class="mb-[2%] mt-[10%] font-bold text-[32px] MEFK text-white w-[30vw]">Nos partenaires qui nous sont fidèles</p>
            <p class="mb-[2%] text-[20px] font-bold Sen w-[40vw] text-[#BDBABB]">Parce qu'ils nous font confiance.</p>
            <p class="mb-[4%] text-[20px] Sen w-[33vw] text-[#BDBABB] max-[1000px]:w-[80vw]">Tazzy et RedBull France s'associent pour créer des projets encore plus grands et plus fous, vous accompagner dans les festivals et s'allier pour encore plus vous faire plaisir.</p>
          </div>
          <img src="@/assets/img/logo-partenaire.png" alt="" class="h-[500px] mt-[7%] mr-[12%] max-[1000px]:hidden">
          <img src="@/assets/img/logoXred.svg" alt="" class="hidden h-[auto] w-[40vw] mr-[12%] max-[1000px]:flex">
        </div>
      </div>
      <div class="triangleBas"></div>
  </section>

    <!-- Section Actualités -->
    <section>
<div class="flex flex-col items-center w-[33%] m-auto text-center">
<h1 class="MEFK text-[30px] text-black">Les actualités de Tazzy</h1>
<p class="Sen text-[18px] text-[#BDBABB]">Une envie de savoir où nous sommes et ce que nous organisons en ce moment ? Voici les dernières actualités de Tazzy !</p>
</div>
 
  <!-- Carousel wrapper -->
<div class="carousel-wrapper overflow-hidden mt-8 relative">
<div class="carousel flex gap-[5vw]">
<!-- Carousel items (the original set) -->
<div class="item flex flex-col w-[37vw] m-auto">
<img src="@/assets/img/coverBonbon.png" alt="Image 1" class="w-[37vw] h-[19.8vw] object-cover">
<div class="flex justify-between items-center">
<div class="flex flex-col gap-1">
<h1 class="MEFK text-[24px] text-black">Nouveaux produits</h1>
<p class="Sen text-[14px] text-[#BDBABB]">Après Ek’tazzy, Tazzy décide de sortir sa nouvelle...</p>
</div>
<div class="flex flex-col gap-1">
<p class="MEFK text-[11px] text-black">Créé le 10/10/24</p>
<p class="Sen text-[11px] text-[#BDBABB]">HugoBohard</p>
</div>
</div>
<p class="Sen text-[14px] text-black mt-6">Tazzy vient de lancer un tout nouveau goût de bonbon, promettant une expérience gustative inédite...</p>
</div>
 
      <div class="item flex flex-col w-[37vw] m-auto">
<img src="@/assets/img/coverBonbon.png" alt="Image 2" class="w-[37vw] h-[19.8vw] object-cover">
<div class="flex justify-between items-center">
<div class="flex flex-col gap-1">
<h1 class="MEFK text-[24px] text-black">Nouveaux produits</h1>
<p class="Sen text-[14px] text-[#BDBABB]">Après Ek’tazzy, Tazzy décide de sortir sa nouvelle...</p>
</div>
<div class="flex flex-col gap-1">
<p class="MEFK text-[11px] text-black">Créé le 10/10/24</p>
<p class="Sen text-[11px] text-[#BDBABB]">HugoBohard</p>
</div>
</div>
<p class="Sen text-[14px] text-black mt-6">Tazzy vient de lancer un tout nouveau goût de bonbon, promettant une expérience gustative inédite...</p>
</div>
 
      <div class="item flex flex-col w-[37vw] m-auto">
<img src="@/assets/img/coverBonbon.png" alt="Image 3" class="w-[37vw] h-[19.8vw] object-cover">
<div class="flex justify-between items-center">
<div class="flex flex-col gap-1">
<h1 class="MEFK text-[24px] text-black">Nouveaux produits</h1>
<p class="Sen text-[14px] text-[#BDBABB]">Après Ek’tazzy, Tazzy décide de sortir sa nouvelle...</p>
</div>
<div class="flex flex-col gap-1">
<p class="MEFK text-[11px] text-black">Créé le 10/10/24</p>
<p class="Sen text-[11px] text-[#BDBABB]">HugoBohard</p>
</div>
</div>
<p class="Sen text-[14px] text-black mt-6">Tazzy vient de lancer un tout nouveau goût de bonbon, promettant une expérience gustative inédite...</p>
</div>
 
      <!-- Duplicate items for smooth looping -->
<div class="item flex flex-col w-[37vw] m-auto">
<img src="@/assets/img/coverBonbon.png" alt="Image 1" class="w-[37vw] h-[19.8vw] object-cover">
<div class="flex justify-between items-center">
<div class="flex flex-col gap-1">
<h1 class="MEFK text-[24px] text-black">Nouveaux produits</h1>
<p class="Sen text-[14px] text-[#BDBABB]">Après Ek’tazzy, Tazzy décide de sortir sa nouvelle...</p>
</div>
<div class="flex flex-col gap-1">
<p class="MEFK text-[11px] text-black">Créé le 10/10/24</p>
<p class="Sen text-[11px] text-[#BDBABB]">HugoBohard</p>
</div>
</div>
<p class="Sen text-[14px] text-black mt-6">Tazzy vient de lancer un tout nouveau goût de bonbon, promettant une expérience gustative inédite...</p>
</div>
</div>
</div>
</section>
 
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      localKaradd1: 0, // Initialisation de localKaradd1 à 0
      localKaradd2: 0, // Initialisation de localKaradd2 à 0
      localKaradd3: 0, // Initialisation de localKaradd3 à 0
      localKaradd4: 0, // Initialisation de localKaradd4 à 0
      localKaradd5: 0, // Initialisation de localKaradd5 à 0
      localKaradd6: 0 // Initialisation de localKaradd1 à 0
    };
  },
  computed: {
    ...mapState(['karadd1', 'karadd2', 'karadd3'])
  },
  methods: {
    ...mapActions(['updateKaradd1', 'updateKaradd2', 'updateKaradd3'])
  }
}
</script>


>>>>>>> kart2
<style scoped>

  * {

    box-sizing: border-box;

  }
 
  .carousel-wrapper {

    width: 100%;

    overflow: hidden;

    position: relative;

  }
 
  .carousel {

    display: flex;

    width: max-content;

    gap: 2rem;

  }
 
  .item {

    flex: 0 0 auto;

  }
 
  @keyframes scroll {

    0% {

      transform: translateX(0);

    }

    100% {

      transform: translateX(calc(-100% / 2)); /* Adjust according to the number of items */

    }

  }
 
  .carousel {

    display: flex;

    gap: 2rem; /* Space between items */

    animation: scroll 30s linear infinite;

  }
</style>

