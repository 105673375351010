<template>
  <div class="slider relative w-[100%] mt-[5%]">

    <video class="w-full h-[70vh] object-cover relative  z-1" autoplay muted loop src="@/assets/video/videoslid.mp4"></video>
    <div class="w-full ">
        <div class="flex flex-col gap-4 absolute w-[45%] z-10 bottom-0 left-0 text-white ml-[10%]  max-sm:w-[80%]">
        <div>
          <p class="font-bold text-[2.5vw] max-lg:text-[3.7vw] max-sm:text-[2rem]">Tazzy <br> Jusqu'au bout de la nuit</p>
          <p class="text-[1vw] max-lg:text-[1.7vw] max-sm:text-[.8rem]">Tazzy, le premier bonbon qui te redonnera un coup de boost après une grande envie de dormir ou une baisse d'énergie.
              Grâce à nos vitamines B12 et notre expertise, c'est le complément parfait pour te recharger.
              Élaboré par des experts, Tazzy est le compagnon idéal pour rester actif et concentré tout au long de la journée
          </p>
        </div>
          <button class="btnens">en savoir plus</button>
          <div class="flex gap-4 my-8">
            <i class="fa-solid fa-angles-left"></i>
            <span class="flex bg-[#C3C3C3] w-[5vw] h-[1vw] rounded-full"></span>
            <span class="flex bg-white w-[1vw] h-[1vw] rounded-full"></span>
            <span class="flex bg-white w-[1vw] h-[1vw] rounded-full"></span>
            <span class="flex bg-white w-[1vw] h-[1vw] rounded-full"></span>
            <span class="flex bg-white w-[1vw] h-[1vw] rounded-full"></span>
            <i class="fa-solid fa-angles-right"></i>
          </div>
        </div>
        
      </div>
  </div>
    
</template>


<script>
export default {
  name: 'SliDer'
}
</script>


