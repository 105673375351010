import { createStore } from 'vuex';

export default createStore({
  state: {
    karadd1: 0,
    karadd2: 0,
    karadd3: 0,
    karadd4: 0, // Ajout de karadd4
    karadd5: 0, // Ajout de karadd5
    karadd6: 0, // Ajout de karadd6
    kart: 0
  },
  mutations: {
    updateKaradd1(state, value) {
      state.karadd1 = value;
    },
    updateKaradd2(state, value) {
      state.karadd2 = value;
    },
    updateKaradd3(state, value) {
      state.karadd3 = value;
    },
    updateKaradd4(state, value) { // Mutation pour karadd4
      state.karadd4 = value;
    },
    updateKaradd5(state, value) { // Mutation pour karadd5
      state.karadd5 = value;
    },
    updateKaradd6(state, value) { // Mutation pour karadd6
      state.karadd6 = value;
    },
    updateKart(state) {
      state.kart = state.karadd1 + state.karadd2 + state.karadd3 + state.karadd4 + state.karadd5 + state.karadd6;
    }
  },
  actions: {
    updateKaradd1({ commit }, value) {
      commit('updateKaradd1', value);
      commit('updateKart');
    },
    updateKaradd2({ commit }, value) {
      commit('updateKaradd2', value);
      commit('updateKart');
    },
    updateKaradd3({ commit }, value) {
      commit('updateKaradd3', value);
      commit('updateKart');
    },
    updateKaradd4({ commit }, value) { // Action pour karadd4
      commit('updateKaradd4', value);
      commit('updateKart');
    },
    updateKaradd5({ commit }, value) { // Action pour karadd5
      commit('updateKaradd5', value);
      commit('updateKart');
    },
    updateKaradd6({ commit }, value) { // Action pour karadd6
      commit('updateKaradd6', value);
      commit('updateKart');
    },
  },
  getters: {
    cartCount: state => state.kart
  }
});